import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../../components/RawHTML';
import Container from '../../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> +[PTBookmark CreateInternal:]</div>
<div class="difference"><span class="status added">Added</span> -[PTBookmark GetHandleInternal]</div>
<div class="difference"><span class="status added">Added</span> +[PTConvert convertOfficeToPDFWithURL:paperSize:completion:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/AnnotTypes.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeRuler</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypePerimeter</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeArea</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameRuler</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNamePerimeter</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameArea</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotationToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonRuler</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonPerimeter</div>
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonArea</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotStyle.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotStyleKeyLabel</div>
<div class="difference"><span class="status added">Added</span> PTAnnotStyleKeyScale</div>
<div class="difference"><span class="status added">Added</span> PTAnnotStyleKeyPrecision</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotStyleDelegate annotStyle:labelDidChange:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotStyleDelegate annotStyle:measurementScaleDidChange:]</div>
<div class="difference"><span class="status added">Added</span> PTAnnotStyle.label</div>
<div class="difference"><span class="status added">Added</span> PTAnnotStyle.measurementScale</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAreaCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAreaCreate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTBookmarkManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTBookmarkManager</div>
<div class="difference"><span class="status added">Added</span> PTBookmarkManager.defaultManager</div>
<div class="difference"><span class="status added">Added</span> -[PTBookmarkManager rootPDFBookmarkForDoc:create:]</div>
<div class="difference"><span class="status added">Added</span> -[PTBookmarkManager removeRootPDFBookmarkForDoc:]</div>
<div class="difference"><span class="status added">Added</span> -[PTBookmarkManager bookmarksForDoc:]</div>
<div class="difference"><span class="status added">Added</span> -[PTBookmarkManager bookmarksForDoc:rootPDFBookmark:]</div>
<div class="difference"><span class="status added">Added</span> -[PTBookmarkManager saveBookmarks:forDoc:]</div>
<div class="difference"><span class="status added">Added</span> -[PTBookmarkManager addBookmark:forDoc:]</div>
<div class="difference"><span class="status added">Added</span> -[PTBookmarkManager updateBookmarksForDoc:pageDeletedWithPageObjNum:]</div>
<div class="difference"><span class="status added">Added</span> -[PTBookmarkManager updateBookmarksForDoc:pageMovedFromPageNumber:pageObjNum:toPageNumber:pageObjNum:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTColorDefaults.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> #def ATTRIBUTE_MEASUREMENT_SCALE</div>
<div class="difference"><span class="status added">Added</span> +[PTColorDefaults setDefaultMeasurementScale:forAnnotType:]</div>
<div class="difference"><span class="status added">Added</span> +[PTColorDefaults defaultMeasurementScaleForAnnotType:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDiffViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDiffViewControllerDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffViewControllerDelegate diffViewController:didCreateDiffFileAtURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffViewControllerDelegate diffViewController:didFailToCompareDocumentsWithError:]</div>
<div class="difference"><span class="status added">Added</span> PTDiffViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffViewController initWithDocument:secondDocument:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffViewController initWithDocument:secondDocument:firstDocumentColor:secondDocumentColor:]</div>
<div class="difference"><span class="status added">Added</span> PTDiffViewController.delegate</div>
<div class="difference"><span class="status added">Added</span> PTDiffViewController.firstDocument</div>
<div class="difference"><span class="status added">Added</span> PTDiffViewController.secondDocument</div>
<div class="difference"><span class="status added">Added</span> PTDiffViewController.firstDocumentColor</div>
<div class="difference"><span class="status added">Added</span> PTDiffViewController.secondDocumentColor</div>
<div class="difference"><span class="status added">Added</span> PTDiffViewController.blendMode</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.pageIndicatorShowsOnPageChange</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.pageIndicatorShowsWithControls</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFileAttachmentCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTFileAttachmentCreate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFileAttachmentHandler.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTFileAttachmentHandlerDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTFileAttachmentHandlerDelegate fileAttachmentHandler:didExportFileAttachment:fromPDFDoc:toURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTFileAttachmentHandlerDelegate fileAttachmentHandler:didFailToExportFileAttachment:fromPDFDoc:withError:]</div>
<div class="difference"><span class="status added">Added</span> PTFileAttachmentHandler</div>
<div class="difference"><span class="status added">Added</span> -[PTFileAttachmentHandler exportFileAttachment:fromPDFDoc:]</div>
<div class="difference"><span class="status added">Added</span> PTFileAttachmentHandler.delegate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFormFillTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTFormFillTool.displaysInputAccessoryView</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTMeasurementScale.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTMeasurementScale</div>
<div class="difference"><span class="status added">Added</span> -[PTMeasurementScale initWithBaseValue:baseUnit:translateValue:translateUnit:precision:]</div>
<div class="difference"><span class="status added">Added</span> PTMeasurementScale.baseValue</div>
<div class="difference"><span class="status added">Added</span> PTMeasurementScale.baseUnit</div>
<div class="difference"><span class="status added">Added</span> PTMeasurementScale.translateValue</div>
<div class="difference"><span class="status added">Added</span> PTMeasurementScale.translateUnit</div>
<div class="difference"><span class="status added">Added</span> PTMeasurementScale.precision</div>
<div class="difference"><span class="status added">Added</span> -[PTMeasurementScale initWithCoder:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTPerimeterCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTPerimeterCreate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTRectangleRedactionCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTRectangleRedactionCreate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTRulerCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTRulerCreate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSelectionRectContainerView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTSelectionRectContainerView initWithPDFViewCtrl:forAnnot:withTool:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull instancetype)initWithPDFViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl forAnnot:(nonnull PTAnnot *)annot withTool:(nonnull PTTool *)tool</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull instancetype)initWithPDFViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl forAnnot:(nullable PTAnnot *)annot withTool:(nonnull PTTool *)tool</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSelectionRectView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTSelectionRectView initWithFrame:forAnnot:withTool:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull instancetype)initWithFrame:(CGRect)frame forAnnot:(nonnull PTAnnot *)annot withTool:(nonnull PTTool *)tool</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull instancetype)initWithFrame:(CGRect)frame forAnnot:(nullable PTAnnot *)annot withTool:(nonnull PTTool *)tool</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTextRedactionCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTTextRedactionCreate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTTool handleFileAttachment:onPageNumber:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PTAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.textAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.linkAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.freeTextAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.lineAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.squareAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.circleAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.polygonAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.polylineAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.highlightAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.underlineAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.squigglyAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.strikeOutAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.stampAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.caretAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.inkAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.popupAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.fileAttachmentAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.soundAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.movieAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.widgetAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.screenAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.printerMarkAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.trapNetAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.watermarkAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.threeDimensionalAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.redactAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.projectionAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.richMediaAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.arrowAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.signatureAnnotationPermission</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.cloudyAnnotationPermission</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTToolEvents tool:handleFileAttachment:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManagerDelegate toolManager:handleFileAttachment:onPageNumber:]</div>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}